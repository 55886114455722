<template>
    <div class="trainee-detail-view">
        <v-container v-if="item">
            <v-row>
                <v-col md="auto" class="mr-10">
                    <v-row align="center">
                        <v-col>
                            <img v-if="item.profilePicture" alt="Profile Photo" :src="item.profilePicture" class="ml-auto mr-auto"
                                 style="width: 167px; height: 167px;"/>
                            <img v-else alt="Profile Photo" src="@/images/profile-photo-default.png" class="ml-auto mr-auto"
                                 style="width: 167px; height: 167px;"/>
                        </v-col>
                        <v-col>
                            <div>{{item.nameFirst}}</div>
                            <div>{{item.nameLast}}</div>
                            <div>{{item.staff}}</div>
                            <div>{{item.region}}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="auto" v-if="stats">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ Math.round(stats.percentUnlocked * 100) }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        % UNLOCKED
                    </div>
                </v-col>
                <v-col md="auto" v-if="stats">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ Math.round(stats.percentCompleted * 100) }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        % COMPLETED
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <div class="headline">LESSON HISTORY</div>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="items" :loading="loading" item-key="id"
                                  hide-default-footer disable-filtering disable-pagination disable-sort>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleDateString()}}
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn :to="`/attempts/${item.id}`" color="primary">View</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <div class="headline">MAP LESSON HISTORY</div>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="itemsmapping" :loading="loading" item-key="id"
                                  hide-default-footer disable-filtering disable-pagination disable-sort>
                        <template v-slot:item.createdAt="{ item }">
                            {{new Date(item.createdAt).toLocaleDateString()}}
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn :to="`/attemptsmapping/${item.id}`" color="primary">View</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "TraineeDetailView",
    props: {
        id: String
    },
    data: () => ({
        loading: false,
        headers: [
            { text: "Lesson", value: "title" },
            { text: "Attempt #", value: "number" },
            { text: "Score", value: "score" },
            { text: "Date", value: "createdAt" },
            { text: "Status", value: "status" },
            { value: "actions" }
        ],
        item: null,
        stats: null,
        lessons: [],
        attempts: [],
        attemptsmapping: [],
        status: ["INCOMPLETE", "COMPLETE"]
    }),
    async mounted() {
        this.item = (await this.$auth.api.get(`/api/trainees/${this.id}`)).data;
        this.stats = (await this.$auth.api.get(`/api/trainees/${this.id}/stats`)).data;
        this.lessons = (await this.$auth.api.get(`/api/lessons`)).data.items;
        this.lessonsmapping = (await this.$auth.api.get(`/api/lessonsmapping`)).data.items;
        this.attempts = (await this.$auth.api.get(`/api/trainees/${this.id}/attempts`)).data.items;
        this.attemptsmapping = (await this.$auth.api.get(`/api/trainees/${this.id}/attemptsmapping`)).data.items;
    },
    computed: {
        items() {
            const map = this.lessons.reduce((dict, lesson) => {
                dict[lesson.id] = lesson.title;
                return dict;
            }, {});
            return this.attempts.map(a => ({
                ...a,
                status: this.status[a.status],
                title: map[a.lessonId]
            }));
        },
        itemsmapping() {
            const map = this.lessonsmapping.reduce((dict, lesson) => {
                dict[lesson.id] = lesson.title;
                return dict;
            }, {});
            return this.attemptsmapping.map(a => ({
                ...a,
                status: this.status[a.status],
                title: map[a.lessonId]
            }));
        }
    }
}
</script>

<style>
.trainee-detail-view table td:nth-child(6) {
    text-align: right !important;
}
</style>