<template>
    <v-app v-if="!$auth.loading && $auth.isAuthenticated">
        <Header/>
        <v-main>
            <v-container fluid>
                <v-row v-if="hasConfirmedProfile">
                    <v-col md="auto" v-if="hasConfirmedProfile">
                        <NavMenu />
                    </v-col>
                    <v-col class="app-content">
                        <router-view/>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <ProfileEditView />
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Header from "./components/Header";
import NavMenu from "@/components/NavMenu";
import ProfileEditView from "@/views/ProfileEditView";
import { getters } from "@/store";

export default {
    name: 'App',
    components: {ProfileEditView, Header, NavMenu },
    computed: { ...getters },
    mounted() {
        if (this.$route.name !== "DashboardView" && !this.hasConfirmedProfile)
            this.$router.push("/");
    }
};
</script>

<style>
.v-main {
    margin-top: 100px;
}
.v-data-table tr:hover {
    background: none !important;
}
.app-content {
    margin-left: 200px;
}
</style>