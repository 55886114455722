import Vue from "vue";
import App from "./App.vue";
import Vuetify from 'vuetify/lib';
import Router from "vue-router";
import routes from "./routes";
import axios from "axios";
//import createAuth0Client from "@auth0/auth0-spa-js";
import { mutators } from "./store";

Vue.config.productionTip = false;

(async function() {
    const config = (await axios.get("/api/config")).data;
    Vue.prototype.$config = config;
    
    const router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        routes,
        scrollBehavior (to, from, savedPosition) {
            return { x: 0, y: 0 }
        }
    });

//     const auth0Client = await createAuth0Client({
//         domain: config.authDomain,
//         client_id: config.authClientId,
//         audience: config.authAudience,
//         redirect_uri: window.location.origin,
//     });
    const auth = new Vue({
        data: () => ({
            loading: true,
            isAuthenticated: false,
            token: null,
            roles: [],
            client: null,
            api:null,
            signoutUrl: config.signoutUrl,
            famAuthWebClientId: config.famAuthWebClientId,
            appLink: config.appLink,
            error: null
        }),
        async created() {
            try {
                if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
                    // FamAuth Step 3: Receive code from FamAuth authorize redirect_uri
                    console.log("FamAuth Step 3: received code");
                    const url = new URL(window.location);
                    let code_in = url.searchParams.get("code");
                    let state_in = url.searchParams.get("state");

                    // Request "CMS" token (FamAuth steps 4-6 will happen on server side)
                    // after FamAuth steps, server side will create a "CMS API" token and return it
                    // this token will allow client side to make api calls 
                    console.log("request CMS API token");
                    var cmsapi_token = await axios.post("/api/famauth-request-cmstoken",{ code: code_in, state: state_in});
                    console.log("receive cms api token");
                    console.log(cmsapi_token);
                    console.log(cmsapi_token.data.accessToken);

                    if(cmsapi_token.data.accessToken == null)
                    {
                        // step 7: redirect if user doesn't have role assigned yet
                        window.location.href = cmsapi_token.data.napRedirectURL;
                        return;
                    }

                    // save token to auth.token
                    this.token = cmsapi_token.data.accessToken;
                    this.isAuthenticated = true;
                    this.loading = false;

                    this.api = axios.create();
                    console.log("setting interceptors");
                    this.api.interceptors.request.use(async config => {
                        const token = this.token;
                        config.headers["Authorization"] = `Bearer ${token}`;
                        return config;
                    });

/*
                    // FamAuth Step 4: Request token
                    console.log("FamAuth Step 4: request token");
                    var famauth_token = await axios.post("/api/famauth-request-token",{ code: code_in, state: state_in});
                    console.log("FamAuth Step 5: receive token");
                    console.log(famauth_token);
                    console.log(famauth_token.data.accessToken);

                    // TODO: confirm token is ok, not null, etc

                    // save token to auth.token
                    this.token = famauth_token.data.accessToken;

                    // got token at this point, get user info
                    // todo???: create an "api token" in CMS instead of using FA token?
                    console.log("FamAuth Step 6: use token to get user info");
                    let famauth_user_info = await axios.get("/api/famauth-user-info?access_token=" + famauth_token.data.accessToken);
                    console.log(famauth_user_info);

                    if(famauth_user_info.data.authorized == "true")
                    {
                        this.isAuthenticated = true;

                        this.loading = false;
                        // todo: set this based on FamAuth role
                        this.roles = ["HeadInstructor"];

                        // todo: instead of using FA token, get a "api token" from CMS
                        // use it to make api calls
                        // do api calls need 2 tokens??? any that ask FA for something?

                        this.api = axios.create();
                        console.log("setting interceptors");
                        this.api.interceptors.request.use(async config => {
                            const token = this.token;
                            config.headers["Authorization"] = `Bearer ${token}`;
                            return config;
                        });
                    }
                    else
                    {
                        console.log("FamAuth Step 7: redirect if user is not in iNAP.");
                        console.log(famauth_user_info.data.napRedirectURL);
                        //window.location.href = famauth_user_info.data.napRedirectURL;
                    }
*/
//                     await this.client.handleRedirectCallback();
//                     await router.push(window.location.pathname);
                }
                else
                {
                    // FamAuth Step 2: redirect to FamAuth authorization server 
                    console.log("FamAuth Step 2: redirect to FamAuth server");
                    let redirect_url = await axios.get("/api/famauth-authorize");
                    console.log(redirect_url);
                    console.log(redirect_url.data.url);
                    window.location.href = redirect_url.data.url;
                }
            }
            catch (e) {
                console.error(e);
            }
            finally {
//                 this.isAuthenticated = await this.client.isAuthenticated();
//                 if (this.isAuthenticated) {
//                     this.user = await this.client.getUser();
//                     this.token = await this.client.getTokenSilently();
//                     this.roles = (await this.client.getIdTokenClaims())["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
//                     this.roles = ["HeadInstructor"];
//                 }
//                 else {
//                     await this.client.loginWithRedirect();
//                 }
//                 this.loading = false

//                   console.log("calling api/profile");
//                   const profile = (await api.get("/api/profile")).data;
//                   mutators.setProfile(profile);
            }
        }
    });
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    Vue.prototype.$auth = auth;
    
//    const api = axios.create();
//    console.log("setting interceptors");
//    api.interceptors.request.use(async config => {
        // todo: this gets set before the token is returned... 
//        const token = auth.token;
//        config.headers["Authorization"] = `Bearer ${token}`;
//        return config;
//    });
    Vue.prototype.$api = auth.api;

    Vue.use(Router);
    Vue.use(Vuetify);
    
//    await sleep(5000);
    // todo: this gets called before token is set... ???

//    console.log("calling auth.api/profile with token...");
//    console.log(auth.token);
    var loops = 1;
    while(auth.token == null && loops < 20)
    {
       await sleep(1000);
       loops = loops + 1;
       console.log(loops);
    }
//    console.log(auth.token);
    const profile = (await auth.api.get("/api/profile")).data;
    mutators.setProfile(profile);
    
    new Vue({
        router,
        vuetify: new Vuetify({
            theme: {
                themes: {
                    options: {
                        customProperties: true
                    },
                    light: {
                        primary: "#42683c"
                    }
                }
            }
        }),
        render: h => h(App)
    }).$mount("#app");
})();
