<template>
    <div class="nav-menu">
        <v-tabs vertical class="nav-menu-tabs" optional>
            <v-tab to="/lessons">
                View Lessons
            </v-tab>
            <v-tab to="/lessonsmapping">
                View Map Lessons
            </v-tab>
            <v-tab to="/instructors" :disabled="profile.role!=='HeadInstructor'">
                View Instructors
            </v-tab>
            <v-tab to="/trainees" :disabled="profile.role!=='HeadInstructor'&&profile.role!=='Instructor'">
                View Trainees
            </v-tab>
            <v-tab to="/leaderboard">
                View Leaderboard
            </v-tab>
            <v-tab to="/addlesson" :disabled="profile.role!=='HeadInstructor' || !showAddLessons">
                Add Lesson
            </v-tab>
        </v-tabs>
        <ProfileSummary v-if="$route.name==='DashboardView'"/>
    </div>
</template>

<script>
import ProfileSummary from "../components/ProfileSummary";
import { getters } from "@/store";

export default {
    name: "NavMenu",    
    data: () => ({
        stats: null,
        showAddLessons: false
    }),
    async mounted() {
        this.stats = (await this.$auth.api.get("/api/dashboard-stats")).data;
        this.showAddLessons = this.stats.totalLessons < 6;
    },
    components: { ProfileSummary },
    methods: {
        async signout() {
        }
    },
    computed: {
        ...getters
    }
};
</script>

<style lang="scss">
.nav-menu {
    width: 200px;
    max-width: 200px;
    position: fixed;
    top: 125px;
    bottom: 0;
    overflow-y: auto;
}
.nav-menu-tabs a {
    outline: none;
}
.nav-menu-tabs [role="tab"] {
    justify-content: flex-end;
}
</style>
