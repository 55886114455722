<template>
    <div class="lesson-list-view">
        <v-container class="mt-8">
            <v-row>
                <div class="headline">MAP LESSON LIST</div>
            </v-row>
            <v-row>
                <v-col md="auto" v-for="i in items" :key="i.id">
                    <router-link :to="`/lessonsmapping/${i.id}`">
                        <v-card>
                            <v-card-text>
                                <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                    <v-row style="height: 100%;" align="center">
                                        <v-col class="text-center">
                                            <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="mt-4">
                                    {{i.title}}
                                </div>
                                <div v-if="isTrainee">
                                    {{i.status}}
                                </div>
                            </v-card-text>
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import { getters } from "@/store";

export default {
    name: "LessonMappingListView",
    data: () => ({
        lessons: [],
        assignments: [],
        status: ["LOCKED","UNLOCKED","COMPLETE"]
    }),
    async mounted() {
        const { items } = (await this.$auth.api.get("/api/lessonsmapping")).data;
        this.lessons = items;
        if (this.isTrainee) {
            const { items } = (await this.$auth.api.get("/api/assignmentsmapping")).data;
            this.assignments = items;
        }
    },
    computed: {
        ...getters,
        isTrainee() {
            return this.profile.role === "Trainee";
        },
        items() {
            if (this.isTrainee) {
                const map = this.lessons.reduce((dict, lesson) => {
                    dict[lesson.id] = lesson.title;
                    return dict;
                }, {});
								                
                let bdtest = this.assignments.map(a => ({
                    id: a.lessonId,
                    status: this.status[a.status],
                    title: map[a.lessonId]
                }));
                function compare(a, b) {
										if (a.id < b.id)
											return -1;
										if (a.id > b.id)
											return 1;
										return 0;
								};
                // sort by id 
                return bdtest.sort(compare);
            }
            else
            {
                return this.lessons;
            }
        }
    }
}
</script>