<template>
    <div class="dashboard-view">
        <v-container v-if="hasConfirmedProfile">
						<v-row justify="space-around" no-gutters align="left">
								<v-col>
										<p >
                                            <a :href="appLink"  target="_blank">UNITY APP DOWNLOAD</a>
										</p>
								</v-col>
						</v-row>
            <v-row v-if="stats">
                <v-col md="auto">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ stats.totalTrainees }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        TOTAL TRAINEES
                    </div>
                </v-col>
                <v-col md="auto">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ Math.round(stats.percentCompleted * 100) }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        % COMPLETED
                    </div>
                </v-col>
                <v-col md="auto">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ stats.totalInstructors }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        TOTAL INSTRUCTORS
                    </div>
                </v-col>
                <v-col md="auto">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ stats.totalLessons }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        TOTAL LESSONS
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "DashboardView",
    data: () => ({
        stats: null,
        appLink: null
    }),
    computed: { ...getters },
    async mounted() {
        this.appLink = this.$auth.appLink;
        this.stats = (await this.$auth.api.get("/api/dashboard-stats")).data;
    }
}
</script>