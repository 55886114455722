<template>
    <v-card class="text-center" style="margin-top: 25px; width: 197px;">
        <v-card-text>
            <img v-if="profile.profilePicture" alt="Profile Photo" :src="profile.profilePicture"
                 class="mt-4 mb-4 ml-auto mr-auto" style="width: 167px; height: 167px;"/>
            <img v-else alt="Profile Photo" src="@/images/profile-photo-default.png" 
                 class="mt-4 mb-4 ml-auto mr-auto" style="width: 167px; height: 167px;"/>
            <div>{{profile.nameFirst}}</div>
            <div>{{profile.nameLast}}</div>
            <div>{{profile.staff}}</div>
            <div>{{profile.region}}</div>
            <div class="mt-3"><v-btn color="primary" to="/profile" text>Edit</v-btn></div>
        </v-card-text>
    </v-card>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "ProfileSummary",
    computed: { ...getters }
}
</script>
