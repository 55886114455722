<template>
    <div class="instructor-list-view">
        <v-container class="mt-8">
            <v-row>
                <div class="headline">INSTRUCTOR LIST</div>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="items" :loading="loading" item-key="id"
                                  hide-default-footer disable-filtering disable-pagination disable-sort>
                        <template v-slot:item.profilePicture="{ item }">
                            <img v-if="!!item.profilePicture" alt="Profile Photo" :src="item.profilePicture" class="mt-2" width="45" height="45" />
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn :to="`/instructors/${item.id}`" color="primary">View</v-btn>
                            <v-btn class="ml-2" :to="`/instructors/${item.id}/edit`" color="primary">Edit</v-btn>
                            <v-btn class="ml-2" @click="remove(item)" color="primary">Remove</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showRemoveDialog" @keydown.esc="showRemoveDialog=false" persistent max-width="500">
            <v-card>
                <v-card-title>Remove Instructor</v-card-title>
                <v-card-text v-if="selectedItem">
                    Please confirm that you would like to remove instructor "{{selectedItem.nameFirst}} {{selectedItem.nameLast}}" by typing
                    the word "delete" in the box below.
                    <v-text-field v-model="removeConfirmText" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text @click="confirmRemove" :disabled="removeConfirmText!=='delete'">Confirm</v-btn>
                    <v-btn color="primary" text @click="showRemoveDialog=false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "InstructorListView",
    data: () => ({
        loading: false,
        headers: [
            { value: "profilePicture" },
            { text: "First Name", value: "nameFirst" },
            { text: "Last Name", value: "nameLast" },
            { text: "Staff", value: "staff" },
            { text: "Region", value: "region" },
            { value: "actions" }
        ],
        items: [],
        selectedItem: null,
        showRemoveDialog: false,
        removeConfirmText: ""
    }),
    mounted() {
        this.refresh();
    },
    methods: {
        async refresh() {
            const { items } = (await this.$auth.api.get("/api/instructors")).data;
            this.items = items;
        },
        remove(item) {
            this.selectedItem = item;
            this.removeConfirmText = "";
            this.showRemoveDialog = true;
        },
        async confirmRemove() {
            const userId = this.selectedItem.id;
            await this.$auth.api.delete(`/api/users/${userId}`);
            this.showRemoveDialog = false;
            await this.refresh();
        }
    }
}
</script>

<style>
.instructor-list-view table td:nth-child(6) {
    text-align: right !important;
}
</style>