import { render, staticRenderFns } from "./ProfileEditView.vue?vue&type=template&id=b60d3172&scoped=true"
import script from "./ProfileEditView.vue?vue&type=script&lang=js"
export * from "./ProfileEditView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b60d3172",
  null
  
)

export default component.exports