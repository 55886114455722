<template>
    <div class="lesson-detail-view">
        <v-container v-if="!!item">
            <div class="headline">{{item.title}}</div>
            <div class="subtitle-1 mt-4">Overview</div>
            <div style="width: 800px; height: 300px; background-color: grey"></div>
            <v-card class="mt-6" style="width: 800px;">
                <v-card-text>
                    <div class="subtitle-1 text-decoration-underline mt-6 mb-6">Lesson Objective</div>
                    <div>{{item.objectives}}</div>
                    <div class="subtitle-1 text-decoration-underline mt-6 mb-6">Lesson Summary</div>
                    <div class="mb-6">{{item.summary}}</div>
                </v-card-text>
            </v-card>
            <v-row class="mt-6 ml-0" style="width: 800px;" justify="space-between">
                <v-btn to="/lessonsmapping" color="primary">Back to List</v-btn>
                <v-btn v-if="profile.role==='HeadInstructor'" :to="`/lessonsmapping/${id}/edit`" color="primary">Edit Lesson</v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "LessonMappingDetailView",
    props: {
        id: String
    },
    data: () => ({
        item: null
    }),
    async mounted() {
        const { data } = await this.$auth.api.get(`/api/lessonsmapping/${this.id}`);
        this.item = data;
    },
    computed: {
        ...getters
    }
}
</script>