<template>
    <div class="leaderboard-view">
        <v-container class="mt-8">
            <v-row>
                <div class="headline">OVERALL TRAINEE LEADERBOARD</div>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="items" :loading="loading" item-key="userId"
                                  hide-default-footer disable-filtering disable-pagination disable-sort>
                        <template v-slot:item.profilePicture="{ item }">
                            <img v-if="!!item.profilePicture" alt="Profile Photo" :src="item.profilePicture" class="mt-2" width="45" height="45" />
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn :to="`/trainees/${item.id}`" color="primary">View</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "LeaderboardView",
    data: () => ({
        loading: false,
        headers: [
            { value: "profilePicture" },
            { text: "First Name", value: "nameFirst" },
            { text: "Last Name", value: "nameLast" },
            { text: "Staff", value: "staff" },
            { text: "Region", value: "region" },
            { text: "Score", value: "score" },
            { value: "actions" }
        ],
        items: []
    }),
    mounted() {
        this.refresh();
    },
    methods: {
        async refresh() {
            const { items } = (await this.$auth.api.get("/api/leaderboard")).data;
            this.items = items;
        }
    }
}
</script>

<style>
.leaderboard-view table td:nth-child(7) {
    text-align: right !important;
}
</style>