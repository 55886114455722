<template>
    <v-container class="lesson-edit-view">
        <v-row>
            <v-col md="8">
                <v-card v-if="!loading">
                    <v-alert v-if="error" type="error">{{error}}</v-alert>
                    <v-form ref="form" v-model="valid" @submit.prevent="submit">
                        <v-card-text>
                            <v-radio-group v-model="role" row :readonly="!!id">
                                <v-radio label="Trainee" value="Trainee" />
                                <v-radio label="Instructor" value="Instructor" />
                            </v-radio-group>
                            <v-text-field label="First Name" v-model="nameFirst" maxlength="100" :rules="requiredRules" />
                            <v-text-field label="Last Name" v-model="nameLast" maxlength="100" :rules="requiredRules" />
                            <v-text-field label="Email" v-model="email" maxlength="250" :rules="requiredRules" :readonly="!!id" />
                            <v-select v-model="staff" :items="staffItems" :rules="requiredRules" label="Staff" :loading="loadingStaffs"/>
                            <v-select v-model="region" :items="regionItems" :rules="requiredRules" label="Region" :loading="loadingRegions"/>
                            <v-select v-if="role==='Trainee'" v-model="instructor" :items="instructorsWithFullNames"
                                      :loading="loadingInstructors"
                                      item-text="name" item-value="id" :rules="requiredRules" label="Instructor"/>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="primary" text type="submit" :disabled="!valid || working" :loading="working">
                                {{this.id ? "Update" : "Add User"}}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserEditView",
    props: {
        id: String
    },
    data: () => ({
        role: "Trainee",
        nameFirst: "",
        nameLast: "",
        email: "",
        password: "",
        staff: "",
        region: "",
        instructor: "",
        requiredRules: [ (v) => !!v || "Required" ],
        passwordRequiredRules: [ 
            (v) => !!v || "Required",
            (v) => v.length >= 8 || "Must be at least 8 characters"
        ],
        passwordRules: [ (v) => (!v || v.length >= 8) || "Must be at least 8 characters"],
        valid: false,
        working: false,
        loading: false,
        error: null,
        staffItems: [],
        regionItems: [],
        loadingInstructors: false,
        loadingRegions: false,
        loadingStaffs: false,
        instructorItems: []
    }),
    async mounted() {
        if (this.id) {
            this.loading = true;
            const item = (await this.$auth.api.get(`/api/users/${this.id}`)).data;
            this.nameFirst = item.nameFirst;
            this.nameLast = item.nameLast;
            this.email = item.email;
            this.staff = item.staff;
            this.region = item.region;
            this.instructor = item.instructorId;
            this.role = item.role;
            this.loading = false;
        }
        this.loadingInstructors = true;
        this.instructorItems = (await this.$auth.api.get("/api/instructors")).data.items;
        this.loadingInstructors = false;
        this.loadingRegions = true;
        this.regionItems = (await this.$auth.api.get("/api/lists/regions")).data.items;
        this.loadingRegions = false;
        this.loadingStaffs = true;
        this.staffItems = (await this.$auth.api.get("/api/lists/staffs")).data.items;
        this.loadingStaffs = false;
    },
    computed: {
        instructorsWithFullNames() {
            return this.instructorItems.map(i => ({
                name: `${i.nameFirst} ${i.nameLast}`,
                id: i.id
            }));
        }
    },
    methods: {
        async submit() {
            this.working = true;
            try {
                if (this.id) {
                    await this.$auth.api.put(`/api/users/${this.id}`, {
                        email: this.email,
                        role: this.role,
                        nameFirst: this.nameFirst,
                        nameLast: this.nameLast,
                        password: this.password.length > 0 ? this.password : null,
                        staff: this.staff,
                        region: this.region,
                        instructorId: this.instructor,
                    });
                }
                else {
                    await this.$auth.api.post("/api/users", {
                        email: this.email,
                        role: this.role,
                        nameFirst: this.nameFirst,
                        nameLast: this.nameLast,
                        password: this.password,
                        staff: this.staff,
                        region: this.region,
                        instructorId: this.instructor,
                    });
                }
                await this.$router.push(`/`);
            }
            catch (e) {
                if (e.response && e.response.status === 409) {
                    this.error = "A user with that email address already exists.";
                }
                else {
                    this.error = "An unexpected error occurred. See log for details.";
                }
            }
            finally {
                this.working = false;
            }
        }
    }
}
</script>
