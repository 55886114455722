<template>
    <v-container class="lesson-edit-view">
        <v-row>
            <v-col md="8">
                <v-card>
                    <v-alert v-if="error" type="error">{{error}}</v-alert>
                    <v-form ref="form" v-model="valid" @submit.prevent="submit">
                        <v-card-text>
                            <v-text-field label="Lesson Name" v-model="title" maxlength="250" :rules="requiredRules" />
                            <v-text-field label="Lesson Objective" v-model="objectives" maxlength="500" :rules="requiredRules" />
                            <v-textarea label="Lesson Summary" v-model="summary" :rules="requiredRules" />
                            <v-text-field label="Possible Score" v-model="possibleScore" :rules="numberRequiredRules" />
                            <v-btn v-if="hasExistingVideo" text color="primary" @click="overviewVideoUrl=null;hasExistingVideo=false;">Remove Existing Video</v-btn>
                            <v-file-input v-else accept="video/mp4,.mp4" label="Overview Video" prepend-icon="mdi-video" ref="fileVideo" />
                            <v-btn v-if="hasExistingData" text color="primary" @click="sequence=null;hasExistingData=false;">Remove Existing Data</v-btn>
                            <v-file-input v-else accept="application/json,.json" label="Data Text File" prepend-icon="mdi-paperclip" 
                                @change="updateDataFile"/>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="primary" text type="submit" :disabled="!valid || !dataFileValid" :loading="working">
                                {{this.id ? "Update" : "Create"}}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "LessonEditView",
    props: {
        id: String
    },
    data: () => ({
        title: "",
        objectives: "",
        summary: "",
        possibleScore: null,
        sequence: null,
        requiredRules: [ (v) => !!v || "Required" ],
        numberRequiredRules: [ 
            (v) => !!v || "Required",
            (v) => isFinite(parseInt(v)) || "Number required"
        ],
        fileRequiredRules: [ (v) => (!!v && v.size > 0) || "Required" ],
        valid: false,
        dataFileValid: true,
        working: false,
        required: false,
        error: null,
        hasExistingData: false,
        hasExistingVideo: false
    }),
    async mounted() {
        if (this.id) {
            const { data } = await this.$auth.api.get(`/api/lessons/${this.id}`);
            this.title = data.title;
            this.objectives = data.objectives;
            this.summary = data.summary;
            this.possibleScore = data.possibleScore;
            this.sequence = data.sequence;
            this.overviewVideoUrl = data.overviewVideoUrl;
            this.hasExistingData = !!this.sequence;
            this.hasExistingVideo = !!this.overviewVideoUrl;
        }
    },
    methods: {
        async updateDataFile(file) {
            this.error = null;
            this.dataFileValid = true;
            if (!file) return;
            const reader = new FileReader();
            reader.onload = evt => {
                try {
                    const data = JSON.parse(evt.target.result);
                    if (!Array.isArray(data.sequence)) {
                        this.error = "Data file does not contain sequence array.";
                        this.dataFileValid = false;
                    }
                    this.sequence = data.sequence;
                }
                catch (ex) {
                    this.dataFileValid = false;
                    console.error(ex);
                    this.error = "Failed to parse data file.";
                }
            };
            reader.readAsText(file, "UTF-8");
        },
        async submit() {
            this.error = "";
            this.working = true;
            
            const lesson = {
                title: this.title,
                objectives: this.objectives,
                summary: this.summary,
                possibleScore: parseInt(this.possibleScore),
                sequence: this.sequence,
                overviewVideoUrl: this.overviewVideoUrl
            };

            try {
                if (this.$refs.fileVideo) {
                    const videoFiles = this.$refs.fileVideo.$el.querySelector("input").files;
                    if (videoFiles && videoFiles.length > 0) {
                        const videoFile = videoFiles[0];
                        const response = await this.$auth.api.post("/api/files", videoFile, {
                            headers: {
                                "Content-Type": videoFile.type || "application/octet-stream"
                            }
                        });
                        lesson.overviewVideoUrl = response.headers["location"];
                    }
                }
                
                if (this.id) {
                    const { data } = await this.$auth.api.put(`/api/lessons/${this.id}`, lesson);
                    await this.$router.push(`/lessons/${data.id}`);
                }
                else {
                    console.log("VIDEO: " + lesson.overviewVideoUrl);
                    const { data } = await this.$auth.api.post("/api/lessons", lesson);
                    await this.$router.push(`/lessons/${data.id}`);
                }
            }
            catch(ex) {
                this.error = "An unexpected error occurred. See log for details.";
                console.error(ex);
            }
            finally {
                this.working = false;
            }
        }
    }
}
</script>
