<template>
    <v-container fluid class="header elevation-2">
        <v-row justify="space-around" no-gutters align="center">
            <v-col>
                <img alt="US Forest Service Logo" src="../images/logo-forest-service.png" height="75px" style="vertical-align: middle" />
                <img class="ml-8" alt="Tall Timbers Logo" src="../images/logo-tall-timbers.png" height="60px" style="vertical-align: middle" />
            </v-col>
            <v-col md="auto">
                <v-btn v-if="$route.name!=='DashboardView'" @click="$router.push('/')" color="primary">Back to Profile</v-btn>
                <v-btn v-else class="ml-4" @click="signout" color="primary">Log Out</v-btn>
                <v-btn class="ml-4" @click="getToken" color="primary">Copy Token</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { getters } from "@/store";

export default {
    name: "Header",
    methods: {
        signout() {
            // unset the bearer token, redirect to Famauth
            this.$auth.api = null;
            window.location = this.$auth.signoutUrl;
        },
        getToken(){
            navigator.clipboard.writeText(this.$auth.token);
        }
    },
    computed: { ...getters }
}
</script>

<style>
.header {
    height: 100px;
    position: fixed;
    background-color: #eeeeee;
    z-index: 1000;
}
.v-tabs a {
    outline: none;
}
</style>
