<template>
    <div class="lesson-attempt-view">
        <v-container v-if="!!lesson && !!item">
            <v-row>
                <v-col md="auto">
                    <div class="headline">Details</div>
                    <v-row>
                        <v-col md="auto">
                            <v-card>
                                <v-card-text class="text-center" style="padding-bottom: 0">
                                    <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                        <v-row style="height: 100%;" align="center">
                                            <v-col>
                                                <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row no-gutters align="center" style="height: 80px;">
                                        <v-col>
                                            <div class="headline">{{ elapsedTime }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="text-center mt-2">
                                ELAPSED TIME
                            </div>
                        </v-col>
                        <v-col md="auto">
                            <v-card>
                                <v-card-text class="text-center" style="padding-bottom: 0">
                                    <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                        <v-row style="height: 100%;" align="center">
                                            <v-col>
                                                <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row no-gutters align="center" style="height: 80px;">
                                        <v-col>
                                            <div class="headline">{{ this.item.score }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="text-center mt-2">
                                MAN SCORE
                            </div>
                        </v-col>
                        <v-col md="auto">
                            <v-card>
                                <v-card-text class="text-center" style="padding-bottom: 0">
                                    <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                        <v-row style="height: 100%;" align="center">
                                            <v-col>
                                                <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row no-gutters align="center" style="height: 80px;">
                                        <v-col>
                                            <div class="headline">{{ this.item.score }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="text-center mt-2">
                                PAY SCORE
                            </div>
                        </v-col>
                        <v-col md="auto">
                            <v-card>
                                <v-card-text class="text-center" style="padding-bottom: 0">
                                    <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                        <v-row style="height: 100%;" align="center">
                                            <v-col>
                                                <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row no-gutters align="center" style="height: 80px;">
                                        <v-col>
                                            <div class="headline">{{item.number}}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <div class="text-center mt-2">
                                ATTEMPT #
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <div class="headline">Summary</div>
                    <v-row>
                        <v-col md="auto">
                            <v-card>
                                <v-card-text>
                                    <div class="mt-6">{{ lesson.title }}</div>
                                    <div class="mb-6">{{ new Date(item.createdAt).toLocaleDateString() }}</div>
                                    {{lesson.summary}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="8">
                    <div class="headline mb-4">
                        Playback
                    </div>
                    <div class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin velit magna, finibus ut pellentesque sit amet, suscipit id turpis. Aenean congue tincidunt erat non tempor.</div>
                    <div>
                        <v-row align="center" no-gutters>
                            <v-col md="9">
                                <v-text-field ref="playbackTxt" readonly v-model="playbackUrl"/>
                            </v-col>
                            <v-col>
                                <v-btn text icon @click="copyPlaybackUrl"><v-icon>mdi-content-copy</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "AttemptDetailView",
    props: {
        id: String
    },
    data: () => ({
        loading: false,
        lesson: null,
        item: null
    }),
    async mounted() {
        this.item = (await this.$auth.api.get(`/api/attempts/${this.id}`)).data;
        this.lesson = (await this.$auth.api.get(`/api/lessons/${this.item.lessonId}`)).data;
    },
    methods: {
        copyPlaybackUrl() {
            const input = this.$refs.playbackTxt.$el.querySelector("input");
            input.focus();
            input.select();
            document.execCommand("copy");
            input.setSelectionRange(0, 0);
            input.blur();
        }
    },
    computed: {
        elapsedTime() {
            if (!this.item)
                return "";
            let sec = this.item.time / 1000;
            let min = Math.floor(sec / 60);
            sec -= min * 60;
            sec = sec.toFixed(4);
            return `${min>0?min + "m" : ""} ${Number(sec)}s`;
        },
        playbackUrl() {
            return `${window.location.origin}/api/attempts/${this.id}`;
        }
    }
}
</script>

<style>
</style>