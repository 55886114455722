<template>
    <v-container class="profile-edit-view">
        <v-row :justify="$route.name==='DashboardView' ? 'center' : 'start'">
            <v-col md="6">
                <v-card>
                    <v-form ref="form" v-model="valid" @submit.prevent="submit">
                        <v-card-text>
                            <div class="headline text-center">Here's what we have for your profile information.</div>
                            <div class="headline text-center">Please confirm or edit.</div>
                            <v-text-field label="First Name" v-model="nameFirst" maxlength="100" :rules="requiredRules" ref="name"/>
                            <v-text-field label="Last Name" v-model="nameLast" maxlength="100" :rules="requiredRules" ref="name"/>
                            <v-select v-model="staff" :items="staffItems" :rules="requiredRules" label="Staff" :loading="loadingStaffs"/>
                            <v-select v-model="region" :items="regionItems" :rules="requiredRules" label="Region" :loading="loadingRegions"/>
                            <v-file-input accept="image/jpg,image/jpeg,.jpg,.jpeg" label="Profile Photo" prepend-icon="mdi-camera" ref="fileImage"/>
                        </v-card-text>
                        <v-card-actions v-if="hasConfirmedProfile">
                            <v-spacer/>
                            <v-btn color="primary" text @click="$router.push('/')">Cancel</v-btn>
                            <v-btn color="primary" text type="submit" :disabled="!valid || working" :loading="working">Update</v-btn>
                        </v-card-actions>
                        <v-card-actions v-else>
                            <v-spacer/>
                            <v-btn color="primary" text type="submit" :disabled="!valid || working" :loading="working">Continue</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { getters, mutators } from "@/store";

export default {
    name: "ProfileEditView",
    data: () => ({
        nameFirst: "",
        nameLast: "",
        staff: "",
        region: "",
        profilePicture: "",
        hasViewedInstructions: false,
        staffItems: [],
        regionItems: [],
        requiredRules: [ (v) => !!v || "Required" ],
        fileRequiredRules: [ (v) => (!!v && v.size > 0) || "Required" ],
        valid: false,
        working: false,
        required: false,
        loadingRegions: false,
        loadingStaffs: false
    }),
    async mounted() {
        if (this.profile) {
            const p = this.profile;
            this.nameFirst = p.nameFirst;
            this.nameLast = p.nameLast;
            this.staff = p.staff;
            this.region = p.region;
            this.profilePicture = p.profilePicture;
            this.hasViewedInstructions = p.hasViewedInstructions;
        }
        this.loadingRegions = true;
        this.regionItems = (await this.$auth.api.get("/api/lists/regions")).data.items;
        this.loadingRegions = false;
        this.loadingStaffs = true;
        this.staffItems = (await this.$auth.api.get("/api/lists/staffs")).data.items;
        this.loadingStaffs = false;
    },
    computed: { ...getters },
    methods: {
        ...mutators,
        async submit() {
            const imgFiles = this.$refs.fileImage.$el.querySelector("input").files;
            if (imgFiles && imgFiles.length > 0) {
                const imgFile = imgFiles[0];
                const response = await this.$auth.api.post("/api/files/profile-picture", imgFile, {
                    headers: {
                        "Content-Type": imgFile.type || "application/octet-stream"
                    }
                });
                this.profilePicture = response.headers["location"];
            }
            const result = await this.$auth.api.put("/api/profile", {
                nameFirst: this.nameFirst,
                nameLast: this.nameLast,
                staff: this.staff,
                region: this.region,
                hasConfirmedProfile: true,
                profilePicture: this.profilePicture,
                hasViewedInstructions: this.hasViewedInstructions
            });
            this.setProfile(result.data);
            if (this.$route.name!=='DashboardView')
                await this.$router.push('/');
        }
    }
}
</script>

<style scoped>

</style>