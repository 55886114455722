<template>
    <div class="instructor-detail-view">
        <v-container v-if="item">
            <v-row>
                <v-col md="auto" class="mr-10">
                    <v-row align="center">
                        <v-col>
                            <img v-if="item.profilePicture" alt="Profile Photo" :src="item.profilePicture" class="ml-auto mr-auto"
                                 style="width: 167px; height: 167px;"/>
                            <img v-else alt="Profile Photo" src="@/images/profile-photo-default.png" class="ml-auto mr-auto"
                                 style="width: 167px; height: 167px;"/>
                        </v-col>
                        <v-col>
                            <div>{{item.nameFirst}}</div>
                            <div>{{item.nameLast}}</div>
                            <div>{{item.staff}}</div>
                            <div>{{item.region}}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="auto" v-if="stats">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ stats.totalTrainees }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        TOTAL ASSIGNED TRAINEES
                    </div>
                </v-col>
                <v-col md="auto" v-if="stats">
                    <v-card>
                        <v-card-text class="text-center" style="padding-bottom: 0">
                            <div class="ml-auto mr-auto" style="width: 167px; height: 167px; background-color: grey">
                                <v-row style="height: 100%;" align="center">
                                    <v-col>
                                        <v-icon color="white" style="font-size: 100px;">mdi-image</v-icon>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters align="center" style="height: 80px;">
                                <v-col>
                                    <div class="headline">{{ Math.round(stats.percentCompleted * 100) }}</div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <div class="text-center mt-2">
                        % COMPLETED
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <div class="headline">ASSIGNED TRAINEES</div>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table :headers="headers" :items="items" :loading="loading" item-key="id"
                                  hide-default-footer disable-filtering disable-pagination disable-sort>
                        <template v-slot:item.profilePicture="{ item }">
                            <img v-if="!!item.profilePicture" alt="Profile Photo" :src="item.profilePicture" class="mt-2" width="45" height="45" />
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn :to="`/trainees/${item.id}`" color="primary">View</v-btn>
                            <v-btn class="ml-2" @click="remove(item)" color="primary">Remove</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showRemoveDialog" @keydown.esc="showRemoveDialog=false" persistent max-width="500">
            <v-card>
                <v-card-title>Remove Trainee</v-card-title>
                <v-card-text v-if="selectedItem">
                    Please confirm that you would like to remove trainee "{{selectedItem.nameFirst}} {{selectedItem.nameLast}}" by typing
                    the word "delete" in the box below.
                    <v-text-field v-model="removeConfirmText" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text @click="confirmRemove" :disabled="removeConfirmText!=='delete'">Confirm</v-btn>
                    <v-btn color="primary" text @click="showRemoveDialog=false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "InstructorDetailView",
    props: {
        id: String
    },
    data: () => ({
        loading: false,
        headers: [
            { value: "profilePicture" },
            { text: "First Name", value: "nameFirst" },
            { text: "Last Name", value: "nameLast" },
            { text: "Staff", value: "staff" },
            { text: "Region", value: "region" },
            { text: "Score", value: "score" },
            { value: "actions" }
        ],
        item: null,
        stats: null,
        items: [],
        selectedItem: null,
        showRemoveDialog: false,
        removeConfirmText: ""
    }),
    mounted() {
        this.refresh();
    },
    methods: {
        async refresh() {
            this.item = (await this.$auth.api.get(`/api/instructors/${this.id}`)).data;
            this.stats = (await this.$auth.api.get(`/api/instructors/${this.id}/stats`)).data;
            const { items } = (await this.$auth.api.get(`/api/trainees?instructor=${encodeURIComponent(this.id)}`)).data;
            this.items = items;
        },
        remove(item) {
            this.selectedItem = item;
            this.removeConfirmText = "";
            this.showRemoveDialog = true;
        },
        async confirmRemove() {
            const userId = this.selectedItem.id;
            await this.$auth.api.delete(`/api/users/${userId}`);
            this.showRemoveDialog = false;
            await this.refresh();
        }
    }
}
</script>

<style>
.instructor-detail-view table td:nth-child(7) {
    text-align: right !important;
}
</style>