import Vue from "vue";

const state = Vue.observable({
    dialog: null,
    profile: null
});

export const getters = {
    dialog: () => state.dialog,
    profile: () => state.profile,
    hasConfirmedProfile: () => !!(state.profile && state.profile.hasConfirmedProfile)
};

export const mutators = {
    setDialog: (v) => state.dialog = v,
    setProfile: (v) => state.profile = v
};
