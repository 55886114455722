import DashboardView from "./views/DashboardView";
import ProfileEditView from "./views/ProfileEditView";
import LessonListView from "./views/LessonListView";
import LessonMappingListView from "./views/LessonMappingListView";
import LessonDetailView from "@/views/LessonDetailView";
import LessonMappingDetailView from "@/views/LessonMappingDetailView";
import LessonEditView from "@/views/LessonEditView";
import LessonMappingEditView from "@/views/LessonMappingEditView";
import InstructorListView from "@/views/InstructorListView";
import InstructorDetailView from "@/views/InstructorDetailView";
import TraineeListView from "@/views/TraineeListView";
import TraineeDetailView from "@/views/TraineeDetailView";
import AttemptDetailView from "@/views/AttemptDetailView";
import AttemptMappingDetailView from "@/views/AttemptMappingDetailView";
import LeaderboardView from "@/views/LeaderboardView";
import UserEditView from "@/views/UserEditView";

export default [
    {
        path: "/",
        name: "DashboardView",
        component: DashboardView
    },
    {
        path: "/profile",
        name: "ProfileEditView",
        component: ProfileEditView
    },
    {
        path: "/lessons",
        name: "LessonListView",
        component: LessonListView
    },
    {
        path: "/lessonsmapping",
        name: "LessonMappingListView",
        component: LessonMappingListView
    },
    {
        path: "/lessons/:id",
        name: "LessonDetailView",
        component: LessonDetailView,
        props: true
    },
    {
        path: "/lessonsmapping/:id",
        name: "LessonMappingDetailView",
        component: LessonMappingDetailView,
        props: true
    },
    {
        path: "/lessons/:id/edit",
        name: "LessonEditView",
        component: LessonEditView,
        props: true
    },
    {
        path: "/lessonsmapping/:id/edit",
        name: "LessonMappingEditView",
        component: LessonMappingEditView,
        props: true
    },
    {
        path: "/addlesson",
        name: "LessonAddView",
        component: LessonEditView
    },
    {
        path: "/addlessonmapping",
        name: "LessonMappingAddView",
        component: LessonMappingEditView
    },
    {
        path: "/instructors",
        name: "InstructorListView",
        component: InstructorListView
    },
    {
        path: "/instructors/:id",
        name: "InstructorDetailView",
        component: InstructorDetailView,
        props: true
    },
    {
        path: "/instructors/:id/edit",
        name: "InstructorEditView",
        component: UserEditView,
        props: true
    },
    {
        path: "/trainees",
        name: "TraineeListView",
        component: TraineeListView
    },
    {
        path: "/trainees/:id",
        name: "TraineeDetailView",
        component: TraineeDetailView,
        props: true
    },
    {
        path: "/trainees/:id/edit",
        name: "TraineeEditView",
        component: UserEditView,
        props: true
    },
    {
        path: "/attempts/:id",
        name: "AttemptDetailView",
        component: AttemptDetailView,
        props: true
    },
    {
        path: "/attemptsmapping/:id",
        name: "AttemptMappingDetailView",
        component: AttemptMappingDetailView,
        props: true
    },
    {
        path: "/leaderboard",
        name: "LeaderboardView",
        component: LeaderboardView
    },
    {
        path: "/adduser",
        name: "UserEditView",
        component: UserEditView
    }
];